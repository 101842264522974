/* purgecss start ignore */
.ds-toggle {
  cursor: pointer;
  background: #8994a6;
  height: 1.5rem;
  width: 2.75rem;
  border-radius: 1000px;
  position: relative;
  transition: background 300ms ease-in-out;
}

.ds-toggle::after {
  cursor: pointer;
  content: ' ';
  box-shadow:
    0 4px 8px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.03);
  background: #fff;
  position: absolute;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  top: -0.15rem;
  left: -7.5%;
  transition: left 300ms ease-in-out;
}

.ds-toggle:focus,
.ds-toggle:focus-visible {
  outline: none;
  box-shadow:
    rgb(255, 255, 255) 0px 0px 0px 0px,
    rgba(59, 130, 246, 0.5) 0px 0px 0px 3px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px;
}

.ds-toggle[aria-checked='true'] {
  background: #608bd8;
}

.ds-toggle[aria-checked='true']::after {
  left: 1.2rem;
}
/* purgecss end ignore */
