/* purgecss start ignore */
.clamp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

table.config-table thead th {
  border-top: none !important;
  border-bottom: none !important;
  box-shadow: inset 0 -1px 0 rgb(229, 231, 235);
}

// Play button
.play-btn-icon,
.play-btn-bg {
  @apply transition-all;
}

.play-btn:hover {
  .play-btn-icon,
  .play-btn-bg {
    transform: scale(1.05);
  }
}

.play-btn:focus .play-btn-bg {
  @apply border-4;
}

// Map
.map__hover-info {
  border-radius: 4px;
  color: #fff;
  padding: 4px 7px;
  background-color: #608bd8;
  transform: translateX(-50%) translateY(-25%);
}

// Related to the homepage and edge caching page animations
.homepage-animation-visitors-label-character,
.edge-caching-animation-visitors-label-character {
  @apply absolute;

  span {
    @apply relative;

    left: var(--offset);
  }

  // V
  &:nth-child(1) {
    --i: 0;
  }

  // I
  &:nth-child(2) {
    --i: 1;
  }

  // S
  &:nth-child(3) {
    --i: 2;
  }

  // I
  &:nth-child(4) {
    --i: 3;
  }

  // T
  &:nth-child(5) {
    --i: 4;
  }

  // O
  &:nth-child(6) {
    --i: 5;
  }

  // R
  &:nth-child(7) {
    --i: 6;
  }

  // S
  &:nth-child(8) {
    --i: 7;
  }
}

.homepage-animation-visitors-label-character {
  // V
  &:nth-child(1) {
    --offset: 0px;
  }

  // I
  &:nth-child(2) {
    --offset: -2px;
  }

  // S
  &:nth-child(3) {
    --offset: -5px;
  }

  // I
  &:nth-child(4) {
    --offset: -8px;
  }

  // T
  &:nth-child(5) {
    --offset: -11px;
  }

  // O
  &:nth-child(6) {
    --offset: -10px;
  }

  // R
  &:nth-child(7) {
    --offset: -9px;
  }

  // S
  &:nth-child(8) {
    --offset: -8px;
  }
}

.edge-caching-animation-visitors-label-character {
  // V
  &:nth-child(1) {
    --offset: 0px;
  }

  // I
  &:nth-child(2) {
    --offset: -2px;
  }

  // S
  &:nth-child(3) {
    --offset: -4px;
  }

  // I
  &:nth-child(4) {
    --offset: -6px;
  }

  // T
  &:nth-child(5) {
    --offset: -8px;
  }

  // O
  &:nth-child(6) {
    --offset: -9px;
  }

  // R
  &:nth-child(7) {
    --offset: -9px;
  }

  // S
  &:nth-child(8) {
    --offset: -11px;
  }
}

.homepage-animation-visitors-label-character {
  offset-path: path(
    'M1 402.942c80.456 4.037 144.471-18.985 209-20.42 90-2 131.5 42.996 218 20.498 69.715-18.132 153.307-71.616 250.615-49.994 54 11.999 69 28.497 129 16.998 110.034-21.088 67.489-215.978 214.995-215.978C1196 154.046 1306.77 111.342 1427 1h374'
  );
}

.edge-caching-animation-visitors-label-character {
  offset-path: path(
    'M1 351.001C114.341 345.001 145.319 387.002 289.659 370.002C434 353.002 407 93.0014 526 93.0014C624.821 93.0015 616 186.001 789 159.001C962 132.001 947.5 404.001 1085 404.001C1135.31 404.001 1170.82 380.575 1201 350.865C1231.18 321.154 1357.87 464.298 1437 428C1546 378 1563.32 435.21 1641 378C1755.55 293.628 1776 1 1776 1'
  );
}

.edge-caching-animation-bars-transition {
  transition-property: transform, background-color;
  transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
  transition-duration: 1s;
}

.edge-caching-animation-stellate-card-transition {
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
  transition-duration: 0.75s;
}

.edge-caching-animation-bars-opacity-transition {
  transition-property: opacity;
  transition-timing-function: ease-in;
  transition-duration: 0.1s;

  &:hover {
    transition-timing-function: ease-out;
    transition-duration: 0.1s;
  }
}

.edge-caching-slide {
  animation: edge-caching-slide-mobile 1s linear infinite reverse;

  @screen md {
    animation: edge-caching-slide-desktop 1s linear infinite reverse;
  }
}

@keyframes edge-caching-slide-mobile {
  0% {
    transform: translateX(-28px);
  }

  100% {
    transform: translateX(28px);
  }
}

@keyframes edge-caching-slide-desktop {
  0% {
    transform: translateX(-34px);
  }

  100% {
    transform: translateX(34px);
  }
}

.mobile-rounded-overflow-fix {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}

.analytics-hero.neutralize-horizontal-padding {
  @media (min-width: 1440px) {
    margin-right: calc((100vw - 100%) / 2 * -1);
    margin-left: calc((100vw - 100%) / 2 * -1);
  }

  @media (min-width: 2140px) {
    margin-right: -500px;
    margin-left: -500px;
  }
}

.diagonal-lines-background {
  background-size: 8px 8px;
  background-image: repeating-linear-gradient(
    -45deg,
    #ffffff88 0,
    #ffffff88 1px,
    transparent 0,
    transparent 50%
  );
}

blockquote.quotation-marks {
  strong {
    @apply font-demibold;
  }
  &:before {
    // left double quotation mark (“)
    content: '\201c';
  }

  // right double quotation mark (”)
  &:after {
    content: '\201d';
  }
}

.case-study-highlighted {
  @apply relative text-white;
  & > * {
    @apply relative z-10;
  }

  &::before {
    @apply content-[''] absolute inset-0 bg-[#608BD8] z-0 -m-1.5 w-full h-full rounded-xl;

    width: calc(100% + 0.75rem);
    height: calc(100% + 0.75rem);
    box-shadow:
      0 6px 20px rgba(59, 76, 106, 0.13),
      0 1.34018px 4.46726px rgba(59, 76, 106, 0.0774939),
      0 0.399006px 1.33002px rgba(59, 76, 106, 0.0525061);
  }
}
/* purgecss end ignore */
