/* purgecss start ignore */

/* react-notion overrides */
.svg-white svg {
  color: white;
}

img {
  user-select: none;
}

.notion-list-numbered {
  padding-left: 1.25em !important;
  list-style-type: decimal;
}

.notion-list-numbered li {
  padding-left: 0.5em !important;
}

.notion-list-numbered li:before {
  content: '' !important;
}

/* recharts overwrites */
.request-bar-chart .recharts-legend-item {
  display: inline-flex !important;
  align-items: center !important;
}
.request-bar-chart .recharts-legend-item {
  margin: 0 10px !important;
}
.request-bar-chart .recharts-legend-item-text {
  margin-left: 2px;
}

.recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child {
  stroke-opacity: 0;
}

.recharts-cartesian-axis-tick {
  @apply font-sans;
  font-weight: 500;
  font-size: 13px;
}

.recharts-legend-wrapper {
  margin-top: -48px;
  padding-left: 18px;
  color: #3b4c6a;
  @apply font-sans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  font-size: 13px;
}

.recharts-default-legend {
  display: inline-flex;
  flex-direction: row-reverse;
}

.recharts-legend-item svg.recharts-surface {
  width: 8px;
  height: 8px;
}

.animate-spin-once {
  animation: spin-reverse 0.5s linear;
}

@keyframes spin-reverse {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-180deg);
  }
}

/* prism light theme */
.light-theme {
  color: #6f42c1;
  .token.function,
  .token.class-name {
    color: #6f42c1;
  }
  .token.keyword {
    color: #d73a49;
  }
  .token.variable {
    color: #e36209;
  }
  .token.comment,
  .token.prolog,
  .token.doctype,
  .token.cdata {
    color: #969896;
  }
  .token.punctuation,
  .token.string,
  .token.atrule,
  .token.attr-value {
    color: rgb(36, 41, 46);
  }
  .token.property,
  .token.tag {
    color: #63a35c;
  }
  .token.boolean,
  .token.number {
    color: rgb(0, 92, 197);
  }
  .token.selector,
  .token.attr-name,
  .token.attr-value .punctuation:first-child,
  .token.regex,
  .token.important {
    color: #795da3;
  }
  .token.operator,
  .token.entity,
  .token.url,
  .language-css .token.string {
    color: #a71d5d;
  }
  .token.key {
    color: rgb(34, 134, 58);
  }
}

// used for inline pre elements in analytics view
.light-pre {
  overflow-x: auto;
  padding-top: 0.8571429em;
  padding-right: 1.1428571em;
  padding-bottom: 0.8571429em;
  padding-left: 1.1428571em;
}

// design system
.border-3 {
  border-width: 3px;
  border-style: solid;
}

.bg-slight-gradient {
  background: linear-gradient(
      180deg,
      rgba(59, 76, 106, 0) 0%,
      rgba(59, 76, 106, 0.03) 60.36%
    ),
    #ffffff;
}

// Text styles
.copy {
  @apply text-15 text-neutral text-opacity-80 font-regular;
}

body .prose {
  @apply max-w-70ch text-neutral-60 sm:text-22;

  figure {
    width: 100% !important;

    & > div {
      padding-bottom: 0 !important;
    }
  }

  strong,
  a {
    @apply text-neutral text-opacity-90;
  }

  a button,
  a button * {
    @apply no-underline;
  }

  h2 {
    @apply text-blue font-medium text-29 mt-16 mb-8 sm:text-38;
  }

  h3,
  h4,
  h5,
  h6 {
    @apply text-gray;
    font-weight: 500;
  }

  pre {
    @apply bg-neutral-10;
  }

  code {
    @apply text-blue;
  }
}

.legal {
  li {
    @apply text-22 font-medium;

    p {
      @apply font-regular text-20 text-opacity-80;
    }
  }
}

body .prose .notion h2 {
  @apply mt-8 mb-4;
}

// ExplainerGraphic
.slide-right {
  animation: slide 0.5s linear infinite;
}

.slide-left {
  animation: slide 0.5s linear infinite reverse;
}

@keyframes slide {
  0% {
    transform: translateX(-6px);
  }

  100% {
    transform: translateX(6px);
  }
}

.bounce-right {
  animation: delayed-bounce-right 7.5s ease-in-out infinite;
}

.bounce-left {
  animation: delayed-bounce-left 7.5s ease-in-out infinite;
}

@keyframes delayed-bounce-right {
  0% {
    transform: translateX(0px);
  }

  90% {
    transform: translateX(0px);
  }

  95% {
    transform: translateX(8px);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes delayed-bounce-left {
  0% {
    transform: translateX(0px);
  }

  90% {
    transform: translateX(0px);
  }

  95% {
    transform: translateX(-8px);
  }

  100% {
    transform: translateX(0px);
  }
}
/* purgecss end ignore */
