/* OperationType, `fragment`, `on` */
.cm-s-stellate .cm-keyword {
  color: #3e6ec0;
}
/* Name (OperationDefinition), FragmentName */
.cm-s-stellate .cm-def {
  color: #714dc0;
}
/* Punctuator (except `$` and `@`) */
.cm-s-stellate .cm-punctuation {
  color: #8993a4;
}
/* Variable */
.cm-s-stellate .cm-variable {
  color: #714dc0;
}
/* NamedType */
.cm-s-stellate .cm-atom {
  color: #714dc0;
}
/* IntValue, FloatValue */
.cm-s-stellate .cm-number {
  color: #d18a00;
}
/* StringValue */
.cm-s-stellate .cm-string {
  color: #16abbb;
}
/* BooleanValue */
.cm-s-stellate .cm-builtin {
  color: #3e6ec0;
}
/* EnumValue */
.cm-s-stellate .cm-string-2 {
  color: #714dc0;
}
/* Name (ObjectField, Argument) */
.cm-s-stellate .cm-attribute {
  color: #2bab7c;
}
/* Name (Directive) */
.cm-s-stellate .cm-meta {
  color: #f3446a;
}
/* Name (Alias, Field without Alias) */
.cm-s-stellate .cm-property {
  color: #2bab7c;
}
/* Name (Field with Alias) */
.cm-s-stellate .cm-qualifier {
  color: #2bab7c;
}
/* Comment */
.cm-s-stellate .cm-comment {
  color: #8993a4;
}
/* Whitespace */
.cm-s-stellate .cm-ws {
  color: #8993a4;
}
/* Invalid characters */
.cm-s-stellate .cm-invalidchar {
  color: #f85b30;
}

.graphiql-container {
  font-family:
    TT Commons Pro Variable,
    system,
    sans-serif;
  --line-height: 1.2;
  --font-family-mono: 'Fira Code', Menlo, Monaco, 'Courier New', monospace;
  --font-weight-regular: 480;
  --font-weight-medium: 580;

  --color-primary: 158, 60%, 42%; /* green-110 in HSL */
  --color-secondary: 347, 100%, 71%; /* magenta-100 in HSL */
  --color-error: 13, 100%, 66%; /* red-100 in HSL */
  --color-warning: 40, 100%, 46%; /* yellow-100 in HSL */
  --color-info: 219, 61%, 61%; /* blue-100 in HSL */
  --color-success: 158, 60%, 42%; /* green-110 in HSL */
  --color-neutral: 218, 28%, 32%; /* neutral-100 in HSL */
  --color-purple: 261, 53%, 59%; /* purple in HSL */
}

.graphiql-embedded {
  border-radius: var(--border-radius-8);
  background-color: hsl(var(--color-base));
  overflow: hidden;
}

.graphiql-editor,
.graphiql-response-editor {
  line-height: 1.5;
}

.graphiql-bg {
  background-color: hsla(var(--color-neutral), var(--alpha-background-light));
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  padding: var(--px-8);
  row-gap: var(--px-8);
}

.graphiql-bg > * {
  flex: 1;
}

.graphiql-controls {
  display: flex;
  grid-column: 1 / 3;
  grid-row: 1 / 2;
  justify-content: space-between;
}

.graphiql-inputs.inputs-only {
  grid-column: span 2;
}

.graphiql-tabs {
  padding: 0;
}

.graphiql-un-styled,
button.graphiql-un-styled.graphiql-tab-button,
button.graphiql-un-styled.graphiql-tab-close,
button.graphiql-un-styled.graphiql-add-tab {
  border-radius: var(--border-radius-8);
}

.graphiql-control-buttons {
  display: flex;
  gap: var(--px-8);
}

.graphiql-control-buttons > .graphiql-un-styled,
.graphiql-un-styled.graphiql-add-tab {
  color: hsla(var(--color-neutral), var(--alpha-tertiary));
  display: block;
}

.graphiql-control-buttons > .graphiql-un-styled:hover,
.graphiql-un-styled.graphiql-add-tab:hover {
  background-color: transparent;
  color: hsla(var(--color-neutral), var(--alpha-secondary));
}

.graphiql-control-buttons > .graphiql-un-styled:focus,
.graphiql-un-styled.graphiql-add-tab:focus {
  outline: 1px solid hsl(var(--color-info));
  outline-offset: -1px;
}

.graphiql-add-tab svg {
  display: block;
  margin: var(--px-8);
}

.graphiql-inputs {
  background-color: hsl(var(--color-base));
  box-shadow: var(--popover-box-shadow);
  border-radius: var(--border-radius-4);
  display: flex;
  flex-direction: column;
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  overflow: hidden;
}

.graphiql-inputs.tabs {
  grid-row: 2 / 3;
}

.graphiql-query-editor {
  display: flex;
  flex: 4;
  gap: var(--px-8);
  padding: var(--px-16);
  position: relative;
}

.graphiql-toolbar {
  display: flex;
  flex-direction: column;
  gap: var(--px-8);
}

.graphiql-un-styled.graphiql-toolbar-button {
  color: hsla(var(--color-neutral), var(--alpha-tertiary));
}

.graphiql-un-styled.graphiql-toolbar-button:hover {
  background-color: transparent;
  color: hsla(var(--color-neutral), var(--alpha-secondary));
}

.graphiql-auth-set {
  position: absolute;
  bottom: -46px;
  right: 48px;

  align-items: center;
  background-color: hsl(var(--color-base));
  border: 1px solid hsla(var(--color-neutral), var(--alpha-background-heavy));
  border-radius: var(--border-radius-4);
  color: hsla(var(--color-neutral), var(--alpha-secondary));
  display: flex;
  gap: var(--px-4);
  padding: var(--px-4);
  z-index: 6;
}

.learn-more-auth {
  margin-top: var(--px-8);
  font-weight: 600;
}

.graphiql-auth-set-tooltip-icon {
  background: hsla(var(--color-neutral), var(--alpha-background-heavy));
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transform: translateY(1px);
}

.graphiql-auth {
  position: absolute;
  bottom: -46px;
  right: 48px;

  align-items: center;
  background-color: hsl(var(--color-warning));
  border: 1px solid hsla(var(--color-neutral), var(--alpha-background-heavy));
  border-radius: var(--border-radius-4);
  display: flex;
  gap: var(--px-4);
  padding: var(--px-4);
  z-index: 6;
}

.graphiql-auth > p {
  color: white;
  font-weight: 600;
}

.graphiql-auth-tooltip-icon {
  background: hsla(var(--color-neutral), var(--alpha-background-light));
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transform: translateY(1px);
}

.graphiql-auth-tooltip-icon > svg {
  color: white;
}

.graphiql-auth-tooltip {
  background-color: hsla(var(--color-neutral));
  margin-left: -4px;
  margin-right: -4px;
  margin-top: -4px;
  margin-bottom: -4px;
  padding: 8px;
  max-width: 40ch;
  white-space: normal;
}

.graphiql-auth-tooltip > p {
  color: white;
}

.graphiql-complexity {
  position: absolute;
  bottom: var(--px-16);
  right: var(--px-16);

  align-items: center;
  background-color: hsl(var(--color-base));
  border: 1px solid hsla(var(--color-neutral), var(--alpha-background-heavy));
  border-radius: var(--border-radius-4);
  color: hsla(var(--color-neutral), var(--alpha-secondary));
  display: flex;
  gap: var(--px-4);
  padding: var(--px-4);
  z-index: 6;
}

.graphiql-complexity-tooltip-icon {
  background: hsla(var(--color-neutral), var(--alpha-background-heavy));
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transform: translateY(1px);
}

.graphiql-complexity-tooltip {
  max-width: 40ch;
  white-space: normal;
}

.graphiql-complexity-tooltip > p {
  color: hsl(var(--color-neutral));
}

.graphiql-complexity-tooltip > p + p {
  margin-top: var(--px-8);
}

.graphiql-secondary-editors-controls {
  border-top: 1px solid
    hsla(var(--color-neutral), var(--alpha-background-heavy));
  display: flex;
  justify-content: space-between;
  padding: var(--px-8);
}

.graphiql-secondary-editors-toggles {
  display: flex;
  gap: var(--px-8);
}

.graphiql-secondary-editors-controls button,
.graphiql-incremental-payloads-toggle button {
  padding: var(--px-12);
}

.graphiql-secondary-editors-controls button.bold {
  font-weight: var(--font-weight-medium);
}

.graphiql-secondary-editors-controls button svg,
.graphiql-incremental-payloads-toggle button svg {
  height: var(--px-12);
  width: var(--px-12);
}

.graphiql-secondary-editors-open {
  flex: 1;
}

.graphiql-outputs {
  display: flex;
  flex-direction: column;
  grid-column: 2 / 3;
  grid-row: 2 / 3;
}

.graphiql-response-editor {
  --editor-background: transparent;
  flex: 1;
  height: 100%;
  margin-left: var(--px-8);
  position: relative;
  width: calc(100% - var(--px-8));
}

.graphiql-incremental-payloads {
  border-top: 1px solid
    hsla(var(--color-neutral), var(--alpha-background-heavy));
  padding: var(--px-8) var(--px-16);
  max-height: 50vh;
  overflow-y: auto;
}

.graphiql-incremental-payloads-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.graphiql-increment-editor .graphiql-editor {
  --editor-background: transparent;
}

.graphiql-response-footer {
  border-top: 1px solid
    hsla(var(--color-neutral), var(--alpha-background-heavy));
  padding: var(--px-20) var(--px-16);
}

[data-reach-tooltip] {
  z-index: 40;
}
