.graphiql-response-plugin {
  display: flex;
  flex-direction: column;
  gap: var(--px-12);
  position: relative;
}

.graphiql-response-summary {
  align-items: center;
  display: flex;
  gap: var(--px-8);
}

.graphiql-stellate-extensions {
  position: absolute;
  top: 0;
  right: 0;
}

.graphiql-tag {
  border-radius: var(--border-radius-4);
  color: hsl(var(--color-neutral));
  font-size: var(--font-size-hint);
  font-weight: var(--font-weight-medium);
  padding: var(--px-4) var(--px-6);
}

.graphiql-tag svg {
  display: inline;
}

.graphiql-cache-state {
  color: hsl(var(--color-base));
}

.graphiql-cache-state.hit {
  background-color: hsl(var(--color-info));
  border: 1px solid hsl(var(--color-info));
}

.graphiql-cache-state.miss {
  background-color: hsl(var(--color-secondary));
  border: 1px solid hsl(var(--color-secondary));
}

.graphiql-cache-state.partial {
  background-color: hsl(var(--color-purple));
  border: 1px solid hsl(var(--color-purple));
}

.graphiql-cache-state.pass {
  background-color: hsl(var(--color-neutral));
  border: 1px solid hsl(var(--color-neutral));
}

.graphiql-cache-state.pass.unintended {
  background-color: hsl(var(--color-error));
  border: 1px solid hsl(var(--color-error));
}

.graphiql-pass-through code {
  background-color: hsla(var(--color-neutral), var(--alpha-background-light));
  border-radius: var(--border-radius-4);
  color: hsl(var(--color-neutral));
  font-size: 0.9em;
  padding: 0.12em 0.3em 0.15em 0.3em;
  white-space: nowrap;
}

.graphiql-rate-limit-decision {
  text-decoration: none;
}

.graphiql-rate-limit-decision.would-block {
  border: 1px solid hsl(var(--color-warning));
  color: hsl(var(--color-warning));
}

.graphiql-rate-limit-decision.blocked {
  border: 1px solid hsl(var(--color-error));
  color: hsl(var(--color-error));
}

.graphiql-rate-limit-rules {
  display: flex;
  flex-direction: column;
  gap: var(--px-8);
}

.graphiql-rate-limit-rule {
  border: 1px solid hsla(var(--color-neutral), var(--alpha-background-heavy));
  display: inline-flex;
  margin-right: var(--px-8);
  padding: 0;
}

.graphiql-rate-limit-rule > * {
  padding: var(--px-4) var(--px-6);
}

.graphiql-rate-limit-rule > * + * {
  border-left: 1px solid
    hsla(var(--color-neutral), var(--alpha-background-heavy));
}

.graphiql-secondary {
  color: hsla(var(--color-neutral), var(--alpha-secondary));
}

.flex-grow {
  flex-grow: 1;
}
