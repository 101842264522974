@tailwind base;

@layer base {
  /* Text styles */
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  // Created weird scrolling on route change
  // html {
  //   scroll-behavior: smooth;
  // }

  body {
    @apply font-sans text-neutral;

    font-variation-settings: 'slnt' 0;
  }

  svg {
    @apply flex-shrink-0;
  }

  .marketing-page,
  .marketing-page .notion {
    @apply text-18 font-sans font-medium text-neutral leading-1.5;

    h1,
    .h1-size,
    h2,
    .h2-size,
    h3,
    .h3-size,
    h4,
    .h4-size,
    h5,
    .h5-size,
    h6,
    .h6-size {
      @apply font-demibold leading-1.3 break-words xs:break-normal;
    }

    h1,
    .h1-size {
      @apply text-36 text-left sm:text-center md:text-52 md:leading-1.2 lg:text-64;
    }

    h2,
    .h2-size {
      @apply text-32 md:text-52;
    }

    h3,
    .h3-size {
      @apply text-29 md:text-38;

      &.small {
        @apply text-22;
      }
    }

    h4,
    .h4-size {
      @apply text-22 md:text-29;
    }

    h5,
    .h5-size {
      @apply text-18 md:text-22;
    }

    h6,
    .h6-size {
      @apply text-18;
    }

    .large-body-text {
      @apply text-18 md:text-22;
    }

    small,
    .small-body-text {
      @apply text-15 leading-1.4;
    }

    .label {
      @apply text-15 font-demibold leading-1 tracking-widest uppercase;
    }

    .code {
      @apply font-mono font-regular leading-1.7 text-18 md:text-22;
    }

    .inline-code {
      @apply text-blue font-medium bg-blue-15 px-1 rounded-2 pb-0.5;
    }

    .link {
      @apply text-blue transition-colors hover:text-blue-110 focus:text-blue-110;
    }

    .rich-text,
    .rich-text .notion {
      @extend .large-body-text;
      @apply max-w-896 mx-auto;

      // Remove margin-top of the first element
      & > *:first-child,
      & > .notion div.notion-viewport + div,
      & > .notion div.notion-viewport + div > *:first-child,
      ul.notion-list {
        @apply mt-0;
      }

      // Remove margin-bottom of the last element
      & > *:last-child {
        @apply mb-0;
      }

      h1 {
        @extend .h2-size;
        @extend .spacer-2xl;
      }

      h2 {
        @extend .h3-size;
        @extend .spacer-xl;
      }

      h3 {
        @extend .h4-size;
        @extend .spacer-lg;
      }

      h4 {
        @extend .h5-size;
        @extend .spacer-md;
      }

      h5 {
        @extend .h5-size;
        @extend .spacer-sm;
      }

      h6 {
        @extend .h5-size;
        @extend .spacer-xs;
      }

      p,
      blockquote,
      pre,
      ol,
      ul,
      .notion-text {
        @extend .spacer-xs;
      }

      hr {
        @extend .spacer-md;
      }

      figure {
        @extend .spacer-sm;
        @apply rounded-12 overflow-hidden border border-neutral-7 shadow-sm;
      }

      blockquote {
        @apply relative text-neutral-60 ml-6 md:ml-8;

        &::before {
          @apply absolute -left-6 md:-left-8 bottom-0 w-1 h-full bg-blue;
          content: '';
        }
      }

      a,
      a.notion-link {
        @apply text-blue font-demibold transition-colors hover:text-blue-110 focus:text-blue-110 no-underline;
      }

      strong,
      b,
      strong a,
      b a {
        @apply font-bold;
      }

      pre,
      code {
        @apply font-regular md:text-20;
      }

      code {
        @apply bg-neutral-7 py-1 px-1.5 rounded-6 text-neutral text-18;
      }

      ol,
      ul {
        li,
        li p,
        li:first-child h3:first-child,
        li:first-child h4:first-child,
        li:first-child h5:first-child,
        li:first-child h6:first-child {
          @extend .spacer-2xs;
        }
      }

      ol {
        @apply list-decimal ml-5.5 pl-3.5;
        // counter-reset: listcounter;

        li {
          // counter-increment: listcounter;

          &::marker {
            @extend .h6-size;
            @apply font-sans text-right text-blue-110;
            // If we use this, nested OLs will have the same type of counter which looks bad, check /handbook/our-culture (it has a lot of nested OLs)
            // content: counters(listcounter, '.') ' ';
          }
        }
      }

      ol ol {
        list-style-type: upper-alpha;

        ol {
          list-style-type: upper-roman;

          ol {
            list-style-type: decimal;

            ol {
              list-style-type: upper-alpha;
            }
          }
        }
      }

      ul {
        @apply list-disc ml-5.5 pl-3.5;

        li {
          &::marker {
            @apply text-blue;
          }
        }
      }

      ul ul {
        list-style-type: none;

        li {
          @apply relative;

          &::before {
            @apply h-[6px] w-[6px] border-[1px] top-4 md:h-[8.5px] md:w-[8.5px] md:border-[1.5px] md:top-[18px] border-blue block absolute rounded-full -left-4 md:left-[-18px];

            content: '';
          }
        }

        ul {
          list-style-type: square;

          ul {
            list-style-type: disc;
          }
        }
      }

      li {
        @apply min-w-[240px];
      }

      // Notion specific styles
      a.notion-hash-link {
        @apply fill-current text-blue-110;
        @extend .min-clickable-area;
      }

      a.notion-page-link {
        @apply h-fit-content;

        span {
          @apply whitespace-normal;
        }
      }

      h2 a.notion-hash-link {
        @apply mt-[10px] md:mt-4;
      }

      h3 a.notion-hash-link {
        @apply mt-[6px] md:mt-[10px];
      }

      h4 a.notion-hash-link {
        @apply mt-[3px] md:mt-[6px];
      }

      main.notion {
        @apply m-0 p-0 max-w-unset w-full;
      }

      div.notion-text {
        @apply w-fit-content;
      }

      blockquote.notion-quote {
        @apply border-l-0 p-0;
        @extend .large-body-text;
      }

      a.notion-link {
        @apply border-0 opacity-100;
      }

      div.notion-callout {
        @apply text-18 inline-flex space-x-2 p-8 rounded-12 bg-neutral-7 w-full border-none mb-0;
        @extend .spacer-xs;

        .notion-callout-text {
          @apply whitespace-pre-wrap text-neutral-60;

          b,
          em {
            @apply text-neutral;
          }
        }
      }

      .text-with-images > div > *:first-child {
        @apply mt-0;
      }

      table {
        @apply min-w-full border-collapse bg-white;
        @extend .spacer-sm;
      }

      th {
        @apply bg-neutral-7 p-4 text-left border border-neutral-15;
        font-size: 16px;
        p {
          @apply mt-0;
        }
      }

      td {
        @apply text-18 p-4 text-left border border-neutral-15;

        p {
          @apply mt-0;
        }
      }
    }

    .simple-rich-text {
      b {
        @apply font-demibold;
      }
      &.theme-blue {
        b {
          @apply text-blue;
        }
      }
      &.theme-red {
        b {
          @apply text-red;
        }
      }
      &.theme-magenta {
        b {
          @apply text-magenta;
        }
      }
    }
  }
}

@tailwind components;

@layer components {
  /* Horizontal section paddings & neutralize utility classes */
  .horizontal-padding {
    @apply px-6 xs:px-8 sm:px-12 md:px-20 lg:px-30;
  }

  .neutralize-horizontal-padding {
    @apply -mx-6 xs:-mx-8 sm:-mx-12 md:-mx-20 lg:-mx-30;
  }

  .reduce-horizontal-padding {
    @apply -mx-3 xs:-mx-3.75 sm:-mx-4.5 md:-mx-10 lg:-mx-14;
  }

  // Useful when an element overflows the screen. When used on the last section of the element, if the user scrolls all the way to the right, there's still a margin between the table and the edge of the screen.
  .phantom-margin-right {
    @apply relative;

    &::after {
      @apply absolute right-0 top-0 transform translate-x-full h-full w-6 xs:w-8 sm:w-12 md:w-20 lg:w-30;
      content: '';
    }
  }

  .small-phantom-margin-right {
    @apply relative;

    &::after {
      @apply absolute right-0 top-0 transform translate-x-full h-full w-6 md:w-8;
      content: '';
    }
  }

  /* Vertical margins between sections and elements */
  .spacer-2xl {
    /* 160px (desktop) */
    @apply mt-20 md:mt-30 lg:mt-40;
  }

  .spacer-2xl-padding {
    /* 160px (desktop) */
    @apply pt-20 md:pt-30 lg:pt-40;
  }

  .spacer-xl {
    /* 120px (desktop) */
    @apply mt-16 md:mt-20 lg:mt-30;
  }

  .spacer-lg {
    /* 80px (desktop) */
    @apply mt-10 md:mt-16 lg:mt-20;
  }

  .spacer-md {
    /* 64px (desktop) */
    @apply mt-8 md:mt-10 lg:mt-16;
  }

  .spacer-sm {
    /* 40px (desktop) */
    @apply mt-6 md:mt-8 lg:mt-10;
  }

  .spacer-xs {
    /* 32px (desktop) */
    @apply mt-6 lg:mt-8;
  }

  .spacer-2xs {
    /* 24px (desktop) */
    @apply mt-4 lg:mt-6;
  }

  .spacer-3xs {
    /* 16px */
    @apply mt-4;
  }

  .spacer-4xs {
    /* 8px */
    @apply mt-2;
  }

  /* Inner paddings */
  .card-padding {
    /* 32px (desktop) */
    @apply p-6 md:p-8;
  }

  /* Layouts */
  .two-column-layout {
    @apply max-w-1200 mx-auto flex flex-col lg:grid lg:grid-cols-2;
  }

  /* Interaction styles */
  .hover-translate-up {
    @apply transition-transform transform hover:-translate-y-1;

    transform: translate3D(0, 0, 0);
  }

  /* Visual elements */
  .tag {
    @apply bg-blue-110 text-white rounded px-2 py-[2px];
  }

  .job-video-iframe {
    @apply relative w-full pt-16/9;

    iframe {
      @apply w-full h-full absolute left-0 top-0;
    }
  }

  /* Use on visually small buttons/links to ensure they have
  at least 42px height/width of clickable area */
  .min-clickable-area {
    @apply relative;

    &::after {
      @apply absolute w-full h-full min-w-10.5 min-h-10.5 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;

      content: '';
    }

    &.large:after {
      @apply w-[calc(100%+24px)];
    }
  }

  .modal-menu-safearea:before {
    @apply absolute w-full h-4 -top-4 left-0;

    content: '';
  }

  .mobile-menu-link {
    @apply min-clickable-area w-full flex items-center px-3 py-2 text-base font-demibold transition duration-150 ease-in-out rounded-md text-gray hover:bg-gray-100 focus:outline-none focus:bg-gray-100;
  }

  /* Max text lines */
  .max-3-lines {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  /* Gradients */
  .transparent-to-white {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }

  .dark-right-fade {
    background: linear-gradient(
      270deg,
      #3b4c6a 0%,
      rgba(59, 76, 106, 0.991353) 6.67%,
      rgba(59, 76, 106, 0.96449) 13.33%,
      rgba(59, 76, 106, 0.91834) 20%,
      rgba(59, 76, 106, 0.852589) 26.67%,
      rgba(59, 76, 106, 0.768225) 33.33%,
      rgba(59, 76, 106, 0.668116) 40%,
      rgba(59, 76, 106, 0.557309) 46.67%,
      rgba(59, 76, 106, 0.442691) 53.33%,
      rgba(59, 76, 106, 0.331884) 60%,
      rgba(59, 76, 106, 0.231775) 66.67%,
      rgba(59, 76, 106, 0.147411) 73.33%,
      rgba(59, 76, 106, 0.0816599) 80%,
      rgba(59, 76, 106, 0.03551) 86.67%,
      rgba(59, 76, 106, 0.0086472) 93.33%,
      rgba(59, 76, 106, 0) 100%
    );
  }

  .dark-bottom-fade {
    background: linear-gradient(
      0deg,
      #3b4c6a 0%,
      rgba(59, 76, 106, 0.991353) 6.67%,
      rgba(59, 76, 106, 0.96449) 13.33%,
      rgba(59, 76, 106, 0.91834) 20%,
      rgba(59, 76, 106, 0.852589) 26.67%,
      rgba(59, 76, 106, 0.768225) 33.33%,
      rgba(59, 76, 106, 0.668116) 40%,
      rgba(59, 76, 106, 0.557309) 46.67%,
      rgba(59, 76, 106, 0.442691) 53.33%,
      rgba(59, 76, 106, 0.331884) 60%,
      rgba(59, 76, 106, 0.231775) 66.67%,
      rgba(59, 76, 106, 0.147411) 73.33%,
      rgba(59, 76, 106, 0.0816599) 80%,
      rgba(59, 76, 106, 0.03551) 86.67%,
      rgba(59, 76, 106, 0.0086472) 93.33%,
      rgba(59, 76, 106, 0) 100%
    );
  }

  .neutral-on-dark-10-bottom-fade {
    background: linear-gradient(
      0deg,
      rgba(71, 92, 128, 1) 0%,
      rgba(71, 92, 128, 0.991353) 6.67%,
      rgba(71, 92, 128, 0.96449) 13.33%,
      rgba(71, 92, 128, 0.91834) 20%,
      rgba(71, 92, 128, 0.852589) 26.67%,
      rgba(71, 92, 128, 0.768225) 33.33%,
      rgba(71, 92, 128, 0.668116) 40%,
      rgba(71, 92, 128, 0.557309) 46.67%,
      rgba(71, 92, 128, 0.442691) 53.33%,
      rgba(71, 92, 128, 0.331884) 60%,
      rgba(71, 92, 128, 0.231775) 66.67%,
      rgba(71, 92, 128, 0.147411) 73.33%,
      rgba(71, 92, 128, 0.0816599) 80%,
      rgba(71, 92, 128, 0.03551) 86.67%,
      rgba(71, 92, 128, 0.0086472) 93.33%,
      rgba(71, 92, 128, 0) 100%
    );
    content: '';
  }
}

@tailwind utilities;
