/* purgecss start ignore */
.oauth-button {
  @apply transition-colors;
}

.oauth-button > svg {
  @apply flex-shrink-0 mr-2.5;
}

.oauth-button.gitlab:not(:hover) {
  background: #9a70c2;
}

.oauth-button.gitlab:hover {
  background: #a684c8;
}

.oauth-button.github:not(:hover) {
  background: #323a4d;
}

.oauth-button.github:hover {
  background: #4b5672;
}
/* purgecss end ignore */
