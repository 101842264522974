:root {
  --cookie-banner-margin: 16px;
}

// repeat id selector for specificity
#CybotCookiebotDialog#CybotCookiebotDialog#CybotCookiebotDialog {
  box-shadow: 0 30px 70px rgba(0, 0, 0, 0.3);
  height: auto;
  max-height: 70vh;
  max-width: calc(100vw - (2 * var(--cookie-banner-margin)));
  min-width: calc(100vw - (2 * var(--cookie-banner-margin)));
  width: calc(100vw - (2 * var(--cookie-banner-margin)));
  transition-property: transform, opacity, top;
  top: 100%;
  transform: translate(-50%, calc(-100% - var(--cookie-banner-margin)));
  padding: 16px;
  box-shadow:
    0px 6px 20px 0px rgba(57, 73, 98, 0.12),
    0px 2px 4px 0px rgba(57, 73, 98, 0.08),
    0px -1px 2px 0px rgba(57, 73, 98, 0.02),
    0px 0px 0px 0.5px rgba(57, 73, 98, 0.06);
  border-radius: 12px;

  #CybotCookiebotDialogBodyContent {
    padding: 0;
    border-bottom: none;
  }

  #CybotCookiebotDialogBody .CybotCookiebotScrollContainer {
    border-bottom: none;
    max-height: 130px;
    min-height: 100px;
    padding: 0;
  }

  #CybotCookiebotDialogBodyContentTitle {
    margin: 0;
    padding-bottom: 4px;
  }

  #CybotCookiebotDialogBodyEdgeMoreDetails {
    padding: 0;
    margin: 0;
    margin-right: 18px;
  }

  // hide cookiebot logo
  #CybotCookiebotDialogPoweredbyCybot,
  #CybotCookiebotDialogPoweredByText,
  [href="https://www.cookiebot.com/en/what-is-behind-powered-by-cookiebot/"]
  {
    display: none !important;
  }

  #CybotCookiebotDialogHeader {
    display: none;
  }

  #CybotCookiebotDialogBodyButtonsWrapper {
    display: flex;
    flex-direction: row;
    gap: 8px;

    button {
      border-radius: 8px;
      padding: 8px;
      max-width: 200px;
    }
  }

  @media screen and (min-width: 1280px) {
    .CybotCookiebotDialogContentWrapper {
      flex-direction: column;
    }

    #CybotCookiebotDialogTabContent {
      margin: 0;
      padding: 0;
    }

    .CybotCookiebotDialogBodyBottomWrapper {
      margin: 0;
      padding: 0;
      width: auto;
      align-self: flex-end;
    }

    #CybotCookiebotDialogBody .CybotCookiebotScrollContainer {
      max-height: none;
      min-height: 1px;
    }

    #CybotCookiebotDialogFooter {
      padding-top: 16px;
      width: 100%;
    }

    #CybotCookiebotDialogBodyButtons {
      max-width: none;
    }
  }
}
