.mktoForm {
  padding: 0 !important;
  width: 100% !important;
}

.mktoForm .mktoField {
  width: 100% !important;
  height: 40px !important;
}

.mktoForm .mktoButtonRow {
  width: 100% !important;
}

.mktoForm .mktoFormRow .mktoFormCol {
  clear: both !important;
  float: none !important;
}

.mktoForm .mktoFieldWrap {
  float: none !important;
}

.mktoForm .mktoButtonRow .mktoButtonWrap {
  margin-left: 0 !important;
}

.mktoForm .mktoButtonWrap.mktoPaperMashup .mktoButton {
  width: 100% !important;
}

.mktoForm .mktoOffset {
  display: none !important;
}

.mktoForm .mktoGutter {
  display: none !important;
}
